import { sum } from 'lodash-es'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { type MarketplacePack, type Price } from '~/types.ts'

export const ACTIVE_CASE_PRICE_ID = 18 as const
export const INACTIVE_CASE_PRICE_ID = 19 as const
export const DAILY_MONITORING_PRICE_ID = 20 as const
export const WEEKLY_MONITORING_PRICE_ID = 21 as const
export const MONTHLY_MONITORING_PRICE_ID = 22 as const
export const YEARLY_MONITORING_PRICE_ID = 26 as const

export const frequencies = computed(() => ({
  1: { id: DAILY_MONITORING_PRICE_ID, label: $t('daily') },
  7: { id: WEEKLY_MONITORING_PRICE_ID, label: $t('weekly') },
  28: { id: MONTHLY_MONITORING_PRICE_ID, label: $t('monthly') },
  90: { id: MONTHLY_MONITORING_PRICE_ID, label: $t('quarterly') },
  365: { id: YEARLY_MONITORING_PRICE_ID, label: $t('yearly') },
  0: { id: null, label: $t('no-monitoring') },
}))

export const usePrices = defineStore('prices', {
  state: () => ({
    ACTIVE_CASE_PRICE_ID,
    INACTIVE_CASE_PRICE_ID,
    DAILY_MONITORING_PRICE_ID,
    WEEKLY_MONITORING_PRICE_ID,
    MONTHLY_MONITORING_PRICE_ID,
    YEARLY_MONITORING_PRICE_ID,
    prices: {} as Record<string, Price>,
    isFetching: false,

    // Monitoring frequency.
    frequencies,
  }),

  getters: {
    priceToCents() {
      return (priceId: number | null) => {
        if (!priceId) {
          return 0
        }

        return this.prices[priceId]?.price ?? 0
      }
    },

    packsToPrice() {
      return (packs: MarketplacePack[]) => sum(packs.map((pack) => this.priceToCents(pack.price_id)))
    },
  },

  actions: {
    async fetch() {
      if (Object.keys(this.prices).length || this.isFetching) {
        return
      }
      this.isFetching = true

      try {
        const { data } = await api.get<Price[]>('prices')
        this.prices = keyedBy('id', data)
      } finally {
        this.isFetching = false
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePrices, import.meta.hot))
}
