import { DisabledHelp } from '@vartion/ui'
import { acceptHMRUpdate, defineStore } from 'pinia'

import router from '~/router.ts'
import { Cache } from '~/services/cache/Cache.ts'
import { type DataSource, type Group, type IntelligentCheckJob, type Order, type Organization, type OrganizationPolicy, type User, type UserDetailed } from '~/types.ts'

const switchQueue: { resolve: () => void; reject: () => void; id: number }[] = []
let handling = false

let listeningOnId: undefined | number = undefined

export const useOrganization = defineStore('organization', {
  state: (): Organization => ({
    id: 0,
    name: '',
    address: '',
    country: '',
    industry: '',
    city: '',
    postal_code: '',
    created_at: '',
    updated_at: '',
    last_activity_at: '',
    invalid_payment_method_at: '',
    trial_convert_to_customer: false,
    trial_ends_at: null,
    type: 'commercial',
    pending_type_change: null,
    parent_organization_id: null,
    origin: null,
    has_onboarding: false,
    has_onboarding_risk: false,
    has_screening: false,
    has_transaction_monitoring: false,
    deleted_at: null,
    microsoft_marketplace_subscription_id: null,
    current_order: {
      cases_created_count: 0,
      searches_count: 0,
    } as Order,
    maximum_searches: null,
    account_access_consent: true,
    has_veriff: undefined,
    has_hubspot: undefined,
  }),

  getters: {
    canCreateCase(): DisabledHelp {
      return this.canCreateCases(1)
    },

    canCreateCases: (state) => {
      return (count: number) => {
        const disabledHelp = new DisabledHelp()

        if (state.maximum_searches !== null) {
          disabledHelp.add(
            $t('you-have-reached-the-maximum-number-of-searches-that-can-be-performed-this-order'),
            state.current_order.searches_count + count > state.maximum_searches,
          )
        } else {
          disabledHelp.add(
            $t('you-have-reached-the-maximum-number-of-searches-that-can-be-performed-this-month-if-you-would-like-to-create-more-cases-then-please-upgrade-your-plan'),
            state.type === 'free' && state.current_order.searches_count + count > $env.maximum_free_searches,
          )

          disabledHelp.add(
            $t('you-have-exceeded-the-number-of-cases-you-can-make-in-a-trial'),
            state.type === 'trial' && state.current_order.searches_count + count > $env.maximum_trial_searches,
          )
        }

        return disabledHelp
      }
    },

    deletionDate() {
      return (organization: Organization) => {
        if (!organization.invalid_payment_method_at) {
          return undefined
        }

        const date = dayjs(organization.invalid_payment_method_at).add(stores.initialState.gracePeriod.days_until_deleted, 'days')

        if (date.isAfter(dayjs())) {
          return date
        }

        return undefined
      }
    },

    deactivationDate() {
      return (organization: Organization) => {
        if (!organization.invalid_payment_method_at) {
          return undefined
        }

        return dayjs(organization.invalid_payment_method_at).add(stores.initialState.gracePeriod.days_until_inactive, 'days')
      }
    },

    nrOfMaximumSearches(): number {
      if (this.maximum_searches) {
        return this.maximum_searches
      }

      if (this.type === 'free') {
        return $env.maximum_free_searches
      }

      if (this.type === 'trial') {
        return $env.maximum_trial_searches
      }

      return Infinity
    },

    nrOfSearchesRemaining(): number {
      return Math.max(0, this.nrOfMaximumSearches - this.current_order.searches_count)
    },
  },

  actions: {
    async load() {
      const user = stores.user.user
      const organizationId = await Cache.tags('organization').get(user.id)

      let switchable = false
      if (organizationId) {
        if (user.organizations.some((organization) => organization.id === organizationId && isOrganizationValid(organization))) {
          switchable = true
        }
        if (await Cache.tags('globals').get('inGhostMode')) {
          switchable = true
        }
      }

      if (switchable) {
        await this.switch(organizationId)
      } else if (user.organizations.length >= 1) {
        const organization = user.organizations.find((organization) => isOrganizationValid(organization))
        if (organization) {
          await this.switch(organization.id)
        } else {
          for (const organization of user.organizations) {
            const { data: user } = await api.get<UserDetailed>('user', {
              headers: { OrganizationId: organization.id },
            })
            if (user.roles.some((role) => role.permissions.some((permission) => permission.name === 'billingSettings.edit'))) {
              await this.fetch(organization.id)
              await router.push({ path: `/invalid-organization/${user.organizations[0].id}` })

              return
            }
          }

          return
        }
      } else if (user.organizations.length === 0) {
        return
      }

      await Cache.tags('organization').put(user.id, this.id)
    },

    async fetch(id: number) {
      await stores.loading.update({ organization: true, app: true })

      const currentId = this.id
      try {
        api.headers.OrganizationId = id
        const { data } = await api.get(`organizations/${id}`)
        this.$state = data.organization
        stores.organizationFunctions.refresh()
        app.config.globalProperties.$organization = this
        stores.groups.set(data.groups)
        stores.users.set(data.users)
        stores.policies.policies = data.policies
        stores.dataSources.dataSources = data.dataSources
        stores.settings.defaultSettings = data.defaultSettings
        stores.services.set(data.services)
        stores.users.setDeleted(data.deleted_users)
        stores.user.unresolvedMonitoredCasesCount = data.unresolvedMonitoredCasesCount
        stores.user.unreadNotificationsCount = data.unreadNotificationsCount
        stores.user.expiredPassportsCount = data.expiredPassportsCount
        stores.billingSettings.$reset()
        stores.paymentMethod.setOrganization({ id })

        this.listenForEvents()

        await this.cacheGhostMode()
      } catch (error) {
        api.headers.OrganizationId = currentId
        Cache.tags('organization').flush()
        await router.replace('/')
        await stores.loading.update({ organization: false, app: false })

        throw error
      }
      await stores.loading.update({ organization: false, app: false })
    },

    async switch(id: number) {
      const promise = new Promise<void>((resolve, reject) => switchQueue.push({ resolve, reject, id }))

      this.handleSwitchQueue()

      await promise
    },

    async handleSwitchQueue() {
      if (handling) {
        return
      }
      handling = true

      while (switchQueue.length > 0) {
        const item = switchQueue.shift()
        if (item) {
          if (item.id === this.id) {
            item.resolve()
            continue
          }

          try {
            await this.fetch(item.id)
            await stores.user.refresh()
            item.resolve()
          } catch {
            item.reject()
          }
        }
      }

      handling = false
    },

    async update(organization: Partial<Organization>) {
      const { data } = await api.patch<Organization>(`organizations/${this.id}`, organization)
      this.$state = data

      const userOrganization = stores.user.user.organizations.find((organization) => organization.id === this.id)
      if (userOrganization) {
        Object.assign(userOrganization, data)
      }
    },

    async updateType(payload: { type: Organization['type']; reasons?: Record<string, string> }) {
      const { data } = await api.patch<Organization>(`organizations/${this.id}/type`, payload)
      this.$state = data

      const userOrganization = stores.user.user.organizations.find((organization) => organization.id === this.id)
      if (userOrganization) {
        Object.assign(userOrganization, data)
      }
    },

    listenForEvents() {
      if (!window.echo || !this.id) {
        return
      }

      if (listeningOnId) {
        echo.leave(`App.Models.Organization.${listeningOnId}`)
      }

      echo
        .join(`App.Models.Organization.${this.id}`)
        .here((users: User[]) => (stores.users.onlineUsers = new Set(users.map((user) => user.id))))
        .leaving((user: User) => stores.users.onlineUsers.delete(Number(user.id)))
        .joining((user: User) => stores.users.onlineUsers.add(Number(user.id)))
        .listen('DataSourceCreated', ({ dataSource }: { dataSource: DataSource }) => stores.dataSources.dataSources.push(dataSource))
        .listen('DataSourcesUpdated', ({ dataSources }: { dataSources: DataSource[] }) => (stores.dataSources.dataSources = dataSources))
        .listen('OrganizationPolicyUpdated', ({ organizationPolicy }: { organizationPolicy: OrganizationPolicy }) => (stores.policies.policies = organizationPolicy))
        .listen('OrganizationSourcesImported', () => $bus.emit('organizationSourcesImported'))
        .listen('GroupCreated', ({ group }: { group: Group & { users: User[] } }) => {
          const { users, ...groupData } = group
          stores.groups.groups[group.id] = groupData
          stores.groups.updateUsers(group.id, users)
        })
        .listen('GroupUpdated', ({ group }: { group: Group & { users: User[] } }) => {
          const { users, ...groupData } = group
          stores.groups.groups[group.id] = groupData
          stores.groups.updateUsers(group.id, users)
        })
        .listen('GroupDeleted', ({ id }: { id: number }) => {
          delete stores.groups.groups[id]

          for (const user of Object.values(stores.users.users)) {
            user.groups = user.groups.filter((group) => group.id !== id)
          }

          stores.user.user.groups = stores.user.user.groups.filter((group) => group.id !== id)
        })
        .listen('IntelligentCheckJobRunning', ({ job }: { job: IntelligentCheckJob }) => {
          stores.runningIntelligentCheckJobs.add(job)
        })
        .listen('IntelligentCheckJobFinished', ({ job, searchesCount }: { job: IntelligentCheckJob; searchesCount: number }) => {
          stores.runningIntelligentCheckJobs.remove(job)
          if (searchesCount > this.current_order.searches_count) {
            this.current_order.searches_count = searchesCount
          }
        })

      listeningOnId = this.id
    },

    async cacheGhostMode() {
      const ghostMode = await Cache.tags('globals').get('ghostMode')
      if (this.id !== ghostMode) {
        Cache.tags('globals').put('ghostMode', false)
      }

      if (!app.config.globalProperties.$inGhostMode) {
        return
      }

      // Cache recent organizations that were accessed in ghost mode.
      const recents = await Cache.tags('globals').get<number[]>('recentAdministrationOrganizations', [])
      if (recents.includes(this.id)) {
        return
      }

      recents.push(this.id)
      if (recents.length > 3) {
        recents.splice(0, 1)
      }
      Cache.tags('globals').put('recentAdministrationOrganizations', recents)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrganization, import.meta.hot))
}
