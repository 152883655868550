import { $notify, type VNotification } from '@vartion/ui'
import { debounce } from 'lodash-es'

import { type Notification, notifications } from './notifications.ts'

type NotificationComponent = InstanceType<typeof VNotification>

const notificationClicked = (notification: NotificationComponent) => {
  notification.close()
  $bus.emit('dialog.show', 'notifications')
  $bus.emit('notifications.show')
}

class NotificationsQueue {
  queue: Notification[] = []

  constructor() {
    this.notify = debounce(this.notify.bind(this), 3000)
    document.addEventListener('visibilitychange', this.notify)

    return this
  }

  push(notification: Notification) {
    this.queue.push(notification)
    this.notify()
  }

  notify() {
    if (document.hidden || this.queue.length === 0) {
      return
    }

    if (this.queue.length > 1) {
      $notify({
        title: $t('new-notifications'),
        message: $t('count-notifications-were-received', this.queue.length),
        duration: 3000,
        onClick: (notification) => notificationClicked(notification),
      })
    } else if (this.queue.length === 1) {
      const { title, message } = parseNotification(this.queue[0])
      $notify({
        title,
        message,
        duration: 3000,
        onClick: (notification) => notificationClicked(notification),
      })
    }

    $bus.emit('notify')

    this.queue = []
  }
}

function parseNotification(notification: Notification) {
  return {
    message: notifications.createMessage(notification),
    title: notifications.createTitle(notification),
  }
}

export const notificationsQueue = new NotificationsQueue()
