import { config } from '@vartion/ui'
import { debounce } from 'lodash-es'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { setLocale } from '~/services/i18n.ts'
import { type Settings } from '~/types.ts'

const save = debounce(
  async function (settings: Settings) {
    await stores.loading.update({ settings: true })
    await api.post('settings', settings)
    await stores.loading.update({ settings: false })
  },
  2000,
  { leading: true },
)

export const useSettings = defineStore('settings', {
  state: () => ({
    settings: {
      date_format: 'YYYY-MM-DD', // provided as a default for when the user is not authenticated.
    } as Settings,
    defaultSettings: {} as Settings,
  }),

  actions: {
    async load(settings: Settings) {
      this.settings = settings
      config.VDatePicker.displayFormat = this.settings.date_format
      await setLocale(this.settings.interface_language)
    },

    async update(settings?: Partial<Settings>) {
      await stores.loading.update({ settings: true })

      if (settings !== undefined) {
        this.settings = { ...this.settings, ...settings }
        setLocale(this.settings.interface_language)
      }

      const data = { ...this.defaultSettings, ...this.settings }

      try {
        await save(data)
      } catch {
        $message.error($t('api-error'))
      }
    },

    async updateDefaultSettings(settings: Settings) {
      try {
        const { data } = await api.patch(`organization-default-settings/${stores.organization.id}`, settings)
        this.defaultSettings = data
      } catch {
        $message.error($t('api-error'))
      }
    },

    async reset() {
      await api.post('settings', {})
      this.settings = { ...this.defaultSettings }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettings, import.meta.hot))
}
