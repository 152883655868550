<template>
  <div class="case-assignee">
    <template v-if="caseAssignee && caseAssignee.type === 'self'">{{ $t('self-assign') }}</template>
    <template v-else-if="!caseAssignee || !caseAssignee.value">{{ $t('unassigned') }}</template>
    <template v-else-if="caseAssignee.type === 'user'">
      <UserAvatar style="margin-right: 8px" :user-id="caseAssignee.value" />
      {{ $users[caseAssignee.value]?.first_name ?? cachedUsers[caseAssignee.value]?.first_name }}
      {{ $users[caseAssignee.value]?.last_name ?? cachedUsers[caseAssignee.value]?.last_name }}
    </template>
    <template v-else>
      <VIcon icon="Users" style="margin-right: 8px; margin-left: 6px" />
      {{ $groups[caseAssignee.value]?.name ?? cachedGroups[caseAssignee.value]?.name }}
    </template>
  </div>
</template>

<script setup lang="ts">
import { type AssigneeOrSelf } from '~/helpers/index.ts'
import { type CaseModel, type Group, type User } from '~/types.ts'

interface Props {
  value?: AssigneeOrSelf
  caseModel?: CaseModel
}

const props = defineProps<Props>()

const caseAssignee = computed(() => {
  if (props.value) {
    return props.value
  }

  if (props.caseModel) {
    return toAssignee(props.caseModel)
  }

  return undefined
})

watch(
  () => caseAssignee.value,
  (value) => {
    if (!value?.value) {
      return
    }

    if (value?.type === 'user') {
      fetchUserById(value.value)
    } else {
      fetchGroupById(value.value)
    }
  },
  { immediate: true, deep: true },
)
</script>

<script lang="ts">
const cachedUsers = reactive<Record<number, User>>({})
const cachedGroups = reactive<Record<number, Group>>({})

const fetching = {
  users: new Set<number>([]),
  groups: new Set<number>([]),
}

async function fetchUserById(id: number) {
  if (id in cachedUsers || id in stores.users.users || fetching.users.has(id)) {
    return
  }

  fetching.users.add(id)
  try {
    const { data } = await api.get(`users/${id}`)
    cachedUsers[id] = data
  } finally {
    fetching.users.delete(id)
  }
}

async function fetchGroupById(id: number) {
  if (id in cachedGroups || id in stores.groups.groups || fetching.groups.has(id)) {
    return
  }

  fetching.users.add(id)
  try {
    const { data } = await api.get(`groups/${id}`)
    cachedGroups[id] = data
  } finally {
    fetching.groups.delete(id)
  }
}
</script>

<style scoped>
.case-assignee {
  display: flex;
  align-items: center;
}
</style>
