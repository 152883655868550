import { acceptHMRUpdate, defineStore } from 'pinia'

import { keyedBy } from '~/helpers/keyedBy.ts'
import { type AtLeast, type Group, type User } from '~/types.ts'

export const useGroups = defineStore('groups', {
  state: () => ({
    groups: {} as Record<number, Group>,
  }),

  getters: {
    usersPerGroup(state) {
      const groups: Record<number, Record<number, User>> = {}

      for (const key in state.groups) {
        groups[key] = {}
      }

      for (const user of Object.values(stores.users.users)) {
        for (const group of user.groups ?? []) {
          groups[group.id][user.id] = user
        }
      }

      return groups
    },
  },

  actions: {
    async create(payload: { name: string; user_ids: number[] }) {
      const { data } = await api.post<Group & { users: User[] }>('groups', payload)

      const { users, ...groupData } = data
      const usersById = keyedBy(
        'id',
        users.map((user) => ({ id: user.id, groups: user.groups })),
      )

      this.groups[groupData.id] = groupData
      stores.users._updateUsers(usersById)

      if (stores.user.user.id in usersById) {
        stores.user._update({ groups: usersById[stores.user.user.id].groups })
      }
    },

    set(groups: Group | Group[]) {
      if (Array.isArray(groups)) {
        this.groups = keyedBy('id', groups)
      } else {
        this.groups = {
          [groups.id]: groups,
        }
      }

      app.config.globalProperties.$groups = this.groups
    },

    async update(group: AtLeast<Group, 'id'> & { user_ids?: number[] }) {
      const { data } = await api.patch<Group & { users: User[] }>(`groups/${group.id}`, group)

      // The api sends back the users that are in the group.
      const { users, ...groupData } = data

      this.groups[group.id] = { ...this.groups[group.id], ...groupData }

      this.updateUsers(group.id, users)
    },

    async remove({ id, casesAssignee, type, removeCases }: { id: number; casesAssignee?: number; type?: string; removeCases: boolean }) {
      await api.delete(`groups/${id}`, { data: { cases_assignee: casesAssignee, cases_assignee_type: type, remove_cases: removeCases } })

      // Remove the group from all users that were in the group before the update.
      for (const key in stores.users.users) {
        findAndSpliceArray(stores.users.users[key].groups, (item) => item.id === id)
      }

      delete this.groups[id]
    },

    _update(groups: Group | Group[]) {
      if (Array.isArray(groups)) {
        for (const group of groups) {
          this.groups[group.id] = group
        }
      } else {
        const group = groups
        this.groups[group.id] = group
      }
    },

    updateUsers(groupId: number, users: User[]) {
      const existingUsers = copyObject(stores.groups.usersPerGroup[groupId])

      // Remove the group from all users that were in the group before the update.
      for (const key in existingUsers) {
        findAndSpliceArray(existingUsers[key].groups, (item) => item.id === groupId)
      }

      stores.users._updateUsers({
        ...existingUsers,
        ...keyedBy(
          'id',
          users.map((user) => ({ id: user.id, groups: user.groups })),
        ),
      })

      const user = users.find((user) => user.id === stores.user.user.id)
      if (user) {
        stores.user.user.groups = user.groups
      } else {
        stores.user.user.groups = stores.user.user.groups.filter((group) => group.id !== groupId)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroups, import.meta.hot))
}
